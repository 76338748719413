import {
  LandingPage,
  HowItWorks,
  PeRiWizard,
  MailChimpSignUp,
  ExampleScore,
} from "./features";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./components";
import { ThemeProvider, createTheme, CssBaseline, Box } from "@mui/material";

const colors = {
  tan: "#EEEAE2",
  cream: "#FFFEFA",
  white: "#FFFFFF",
  orange: "#EE603E",
  pink: "#FF8466",
  lightPink: "#FFAE9A",
  rust: "#D6816D",
  olive: "#ADA88C",
  gray: "#535057",
  black: "#2C2A2F",
};

export default function App() {
  // const FadeUp = batch(Fade(), Move(), Sticky());
  const theme = createTheme({
    palette: {
      common: {
        black: colors.black,
        white: colors.white,
      },
      primary: {
        light: colors.cream,
        main: colors.olive,
        dark: colors.gray,
        contrastText: colors.orange,
      },
      background: {
        default: colors.tan,
        paper: colors.orange,
      },
    },
    typography: {
      fontFamily: `Poppins, sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  const MainApp = () => {
    return (
      <Box>
        <LandingPage />
        <HowItWorks />
        <ExampleScore />
        <MailChimpSignUp />
        <Footer />
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainApp />} />
          <Route path="/peri/:step" element={<PeRiWizard />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
