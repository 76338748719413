import { useTheme, Breadcrumbs } from "@mui/material";
import { BreadCrumbButton } from "../../components";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { PERI_STEPS } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { getFromLocalStorage } from "../../utils";

export const BreadCrumbs = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const periFormData = getFromLocalStorage("periFormData");

  const breadCrumbKeys = {
    [PERI_STEPS.ADD_COMPANY]: 1,
    [PERI_STEPS.CALCULATE]: 2,
    [PERI_STEPS.EVALUATE]: 3,
    [PERI_STEPS.SIMULATE]: 4,
    [PERI_STEPS.COMING_SOON]: 5,
  };

  const key = periFormData.step as PERI_STEPS;
  const keyParam = params.step as PERI_STEPS;

  const handleCrumbClick = (step: PERI_STEPS) => {
    navigate(`/peri/${step}`);
  };

  const breadcrumbs = [
    <BreadCrumbButton
      key="1"
      onClick={() => {
        if (breadCrumbKeys[key] >= 1) {
          handleCrumbClick(PERI_STEPS.ADD_COMPANY);
        }
      }}
      completed={breadCrumbKeys[keyParam] >= 1}
      theme={theme}
    >
      Step 1: Add Company
    </BreadCrumbButton>,
    <BreadCrumbButton
      key="2"
      completed={breadCrumbKeys[keyParam] >= 2}
      theme={theme}
      onClick={() => {
        if (breadCrumbKeys[key] >= 2) {
          handleCrumbClick(PERI_STEPS.CALCULATE);
        }
      }}
    >
      Step 2: Calculate
    </BreadCrumbButton>,
    <BreadCrumbButton
      key="3"
      completed={breadCrumbKeys[keyParam] >= 3}
      theme={theme}
      onClick={() => {
        if (breadCrumbKeys[key] >= 3) {
          handleCrumbClick(PERI_STEPS.EVALUATE);
        }
      }}
    >
      Step 3: Evaluate
    </BreadCrumbButton>,
    <BreadCrumbButton
      key="4"
      completed={breadCrumbKeys[keyParam] >= 4}
      theme={theme}
      onClick={() => {
        if (breadCrumbKeys[key] >= 4) {
          handleCrumbClick(PERI_STEPS.SIMULATE);
        }
      }}
    >
      Step 4: Simulate
    </BreadCrumbButton>,
    <BreadCrumbButton
      key="5"
      completed={breadCrumbKeys[keyParam] === 5}
      theme={theme}
      onClick={() => console.log("Coming soon")}
    >
      Coming soon
    </BreadCrumbButton>,
  ];

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
