import {
  MEMBER_TYPE,
  boardHeaderData,
  keysOrder,
  mgmtHeaderData,
} from "../constants";
import { BoardMemberData } from "../types";

export function getCookie(name: string): string | null {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }

  return null;
}

export function getFromLocalStorage(item: string) {
  const retrievedItem = window.localStorage.getItem(item);

  if (retrievedItem) {
    return JSON.parse(retrievedItem);
  }
  return {};
}

export function setInLocalStorage(key: string, item: unknown) {
  window.localStorage.setItem(key, JSON.stringify(item));
}

export const getCurrentDate = () => {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = today.getFullYear();
  return `${month}/${day}/${year}`;
};

export const calculateAverage = (diversityData: number[]) => {
  const average =
    (diversityData.reduce((sum, value) => sum + value, 0) /
      diversityData.length) *
    100;

  const formattedAverage = Math.round(average);
  return formattedAverage;
};

export const getNewTableRow = (memberType: MEMBER_TYPE, tableData: any[]) => {
  const boardRow = [
    { readOnly: true, value: `${tableData.length}` },
    { value: "", key: "name" },
    { value: "", key: "age" },
    { value: "", key: "sex" },
    { value: "", key: "race_ethnicity" },
    { value: "", key: "num_other_boards" },
    { value: "", key: "years_on_board" },
    { value: "", key: "num_companies_worked" },
    { value: "", key: "num_industries_worked" },
    { value: "", key: "primary_industry_focus" },
    { value: "", key: "highest_degree" },
    { value: "", key: "subject_of_study_college" },
    { value: "", key: "subject_of_study_highest_degree" },
    { value: "", key: "region_of_education_college" },
    { value: "", key: "region_of_education_highest_degree" },
  ];

  const mgmtRow = boardRow.filter(
    (x) => x.key !== "num_other_boards" && x.key !== "primary_industry_focus"
  );

  return memberType === MEMBER_TYPE.BOARD ? boardRow : mgmtRow;
};

export const formatBoardDataToTableData = (
  data: BoardMemberData[],
  memberType: MEMBER_TYPE,
  provisionalTable: boolean
) => {
  const filteredData = provisionalTable
    ? data.filter((x) => x.provisional)
    : data.filter((x) => !x.provisional);

  const apiBoardMembers = filteredData.map((boardMember) => {
    const allMemberData = Object.entries(boardMember)
      .map((x) => {
        return {
          key: x[0],
          value: typeof x[1] === "number" ? x[1].toString() : x[1],
        };
      })
      .filter(({ key }) => {
        const keyString = key as string;
        return (
          keyString !== "member_type" &&
          keyString !== "id" &&
          keyString !== "company_id" &&
          keyString !== "provisional" &&
          keyString !== "year"
        );
      })
      .sort((a, b) => {
        return keysOrder.indexOf(a.key) - keysOrder.indexOf(b.key);
      });

    const selectedMemberData =
      memberType === MEMBER_TYPE.BOARD
        ? allMemberData
        : allMemberData.filter(({ key }) => {
            const keyString = key as string;
            return (
              keyString !== "num_other_boards" &&
              keyString !== "primary_industry_focus"
            );
          });

    return [
      { readOnly: true, value: boardMember.id.toString() },
      ...selectedMemberData,
    ];
  });

  const selectedTableHeaderData =
    memberType === MEMBER_TYPE.BOARD ? boardHeaderData : mgmtHeaderData;

  const result = [selectedTableHeaderData, ...apiBoardMembers];
  return result;
};

export const formatSubmitPayload = (
  tableData: any[],
  allTableData: any[],
  companyId: number | string,
  memberType: MEMBER_TYPE,
  provisionalTable: boolean
) => {
  const data = tableData.slice(1);
  return data.map((boardMember) => {
    let memberData = [];
    const memberExits = allTableData.find(
      (x) => x.id === parseInt(boardMember[0].value)
    );
    if (memberExits) {
      boardMember[0].key = "id";
      memberData = boardMember;
    } else {
      memberData = boardMember.slice(1, boardMember.length);
    }

    let newBoardMember = {
      company_id: companyId,
      member_type: memberType,
      provisional: provisionalTable,
      year: 2024,
    };
    memberData.forEach((x: any) => {
      // @ts-ignore
      newBoardMember[x.key] = x.value;
    });
    return newBoardMember;
  });
};
