import React from "react";
import { Tabs, styled, Tab, Box, useTheme } from "@mui/material";

interface ToggleProps {
  selectedOption: number;
  onChange: any;
}

export const Toggle = ({ selectedOption, onChange }: ToggleProps) => {
  const theme = useTheme();
  const toggleOptions = [
    <Box sx={{ fontSize: "16px", display: "flex" }}>BOARD</Box>,
    <Box sx={{ fontSize: "16px", display: "flex" }}>MANAGEMENT</Box>,
  ];

  return (
    <Box sx={{ width: "350px" }}>
      <Container theme={theme}>
        <StyledTabs
          theme={theme}
          selectionFollowsFocus={true}
          variant={"fullWidth"}
          defaultValue={0}
          value={selectedOption}
          onChange={onChange}
        >
          {toggleOptions.map((l, i) => {
            return (
              <StyledTab
                disableRipple={true}
                theme={theme}
                key={`tab-label-${i}`}
                label={l}
              />
            );
          })}
        </StyledTabs>
      </Container>
    </Box>
  );
};

const Container = styled(Box)(
  ({ theme }) => `
  height: 100%;
  background: #F5F5F5;
  border-radius: 20px;
  width: 350px;
  margin-bottom: ${theme.spacing(3)};
`
);

const StyledTabs = styled(Tabs)(
  ({ theme }) => `
  height: 100%;
  border-radius: 20px;
  border: 1 px solid black;
  padding: 2px;
  transition: background 0.3s ease;
  min-height: ${theme.spacing(4.75)};

  .MuiButtonBase-root {
    min-width: 0px;
  }
  
  .MuiTabs-indicator {
    z-index: 1;
    background: ${theme.palette.primary.light};
    border-radius: 20px;
    height: 100%;
  }
  .MuiTabs-flexContainer {
    height: 100%;
  }
`
);

const StyledTab = styled(Tab)(
  ({ theme }) => `
  height: 100%;
  font-family: ${theme.typography.fontFamily};
  color: ${theme.palette.common.black};
  font-weight: 500;
  z-index: 2;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  min-height: unset;
  padding: ${theme.spacing(1)};

  &.Mui-selected {
    color: ${theme.palette.primary.dark};
    div {
      color: ${theme.palette.common.black};
    }
  }
`
);

export default Toggle;
