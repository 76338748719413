import { useState, useEffect } from "react";
import { Box, Container, useTheme } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import { useInView } from "react-intersection-observer";
import AddIcon from "@mui/icons-material/Add";
import { TextBlock, Title, ContentCard, ScoreCard } from "../../components";

const ParallaxText = ({
  // @ts-ignore
  children,
  speed = 0.5,
  delay = 4,
  bgColor = "#FFAE9A",
  showGradient = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.01 });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  const styles = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: showGradient ? "#EE603F" : bgColor,
    background: showGradient
      ? "linear-gradient(180deg, #EE603F, #EEEAE2)"
      : bgColor,
    transform: `translateY(${isVisible ? 0 : 50}%)`,
    transition: `transform ${delay}s ease-in-out`,
    opacity: isVisible ? 1 : 0,
  };

  return (
    <div ref={ref} style={styles}>
      {children}
    </div>
  );
};

export const HowItWorks = () => {
  const theme = useTheme();

  return (
    <>
      <ParallaxText
        speed={0.2}
        delay={0.5}
        bgColor={theme.palette.common.black}
      >
        <Container
          sx={{
            maxWidth: "770px !important",
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Title
            sx={{
              textAlign: "left",
              color: theme.palette.primary.contrastText,
              marginBottom: theme.spacing(8),
            }}
          >
            <Box component={"span"} sx={{ color: theme.palette.common.white }}>
              DEI is in
            </Box>{" "}
            crisis
            <Box component={"span"} sx={{ color: theme.palette.common.white }}>
              .
            </Box>
          </Title>
          <Title
            sx={{
              textAlign: "left",
              marginBottom: "0px",
              color: theme.palette.primary.contrastText,
            }}
          >
            <Box component={"span"} sx={{ color: theme.palette.common.white }}>
              The Problem:
            </Box>
          </Title>
          <Title
            sx={{
              color: theme.palette.primary.contrastText,
              textAlign: "left",
              marginTop: "0px",
            }}
          >
            Representation
            <Box component={"span"} sx={{ color: theme.palette.common.white }}>
              -based
              <br />
              diversity reporting creates
              <br />
            </Box>{" "}
            legal, PR and people risks
            <Box component={"span"} sx={{ color: theme.palette.common.white }}>
              .
            </Box>
          </Title>
        </Container>
      </ParallaxText>
      <ParallaxText
        speed={0.2}
        delay={0.5}
        bgColor={theme.palette.background.default}
      >
        <Container
          sx={{
            maxWidth: "1000px !important",
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Title
            sx={{
              textAlign: "center",
              color: theme.palette.primary.contrastText,
            }}
          >
            <Box component={"span"} sx={{ color: theme.palette.common.black }}>
              At its core,{" "}
            </Box>
            diversity{" "}
            <Box component={"span"} sx={{ color: theme.palette.common.black }}>
              is about
            </Box>{" "}
            reducing group-think
          </Title>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: theme.spacing(7, 5),
              gap: theme.spacing(3),
              flexDirection: "column",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                margin: "0px",
                "#arrow-icon": {
                  display: "none",
                },
              },
            }}
          >
            <ContentCard>
              <Box className="card-text">
                <Box component={"span"}>If</Box> "Diversity" is a proxy for
                different perspectives...
              </Box>
            </ContentCard>
            <ContentCard>
              <Box className="card-text">
                <Box component={"span"}>And</Box> perspective, or point of view,
                is informed by background, culture, choices and experience...
              </Box>
            </ContentCard>
            <ForwardIcon
              id="arrow-icon"
              sx={{ fontSize: 120, rotate: "90deg" }}
            />
            <ContentCard>
              <Box className="card-text">
                <Box component={"span"}>Then</Box>{" "}
                <Box
                  component={"span"}
                  sx={{
                    fontWeight: "bold",
                    color: "white !important",
                    fontStyle: "italic",
                  }}
                >
                  Perspective Risk
                </Box>{" "}
                is the risk of group think because there's not enough diversity.
              </Box>
            </ContentCard>
          </Box>
        </Container>
      </ParallaxText>
      <ParallaxText
        speed={0.2}
        delay={0.5}
        bgColor={theme.palette.background.paper}
      >
        <Container
          sx={{
            maxWidth: "1000px !important",
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Title
            sx={{
              textAlign: "center",
              color: theme.palette.common.white,
              width: "78%",
              margin: "auto",
            }}
          >
            PeRi Score, <br />
            <Box
              sx={{
                color: theme.palette.common.black,
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              component={"span"}
            >
              short for Perspective Risk, measures the
            </Box>
            <br />
            <Box
              sx={{
                color: "white !important",
                fontWeight: "bold !important",
                textTransform: "uppercase",
              }}
              component={"span"}
            >
              likelihood of different perspectives{" "}
            </Box>
            <br />
            <Box sx={{ color: theme.palette.common.black }} component={"span"}>
              between any two members of a group
            </Box>
          </Title>
        </Container>
      </ParallaxText>
      <ParallaxText speed={0.2} delay={0.5} showGradient>
        <Container
          sx={{
            maxWidth: "1000px !important",
            minHeight: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "100px",
            marginBottom: "200px",
          }}
        >
          <Title
            sx={{
              textAlign: "center",
              color: theme.palette.common.white,
              marginBottom: theme.spacing(8),
            }}
          >
            PeRi Score combines:
          </Title>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                margin: "0px",
              },
            }}
          >
            <ScoreCard>
              <span>2D Diversity</span>, which factors in...
              <ul>
                <li>
                  <span>“Inherent” diversity</span> or immutable traits like
                  race, age, sex, etc.
                </li>
                <li>
                  <span>“Acquired” diversity</span> or chosen / lived traits
                  like education, career, etc.
                </li>
              </ul>
            </ScoreCard>
            <AddIcon
              sx={{ fontSize: "150px", color: theme.palette.background.paper }}
            />
            <ScoreCard sx={{ width: "61%" }}>
              A widely-used <span>scientific index</span> for measuring{" "}
              <span>diversity</span> in ecosystems
            </ScoreCard>
          </Box>
        </Container>
      </ParallaxText>
    </>
  );
};

export default HowItWorks;
