import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { ContentContainer, Footer, NavElementLink } from "../../components";
import { API_URL, PERI_STEPS } from "../../constants";
import PeriLogin from "./peri-login";
import AddCompany from "./add-company";
import Calculate from "./calculate";
import Simulate from "./simulate";
import BreadCrumbs from "./bread-crumbs";
import Evaluate from "./evaluate";
import { set } from "mobx";
import { setInLocalStorage } from "../../utils";

export interface PeRiWizardParams {
  step?: PERI_STEPS;
}

export const PeRiWizard = () => {
  const params = useParams();

  const isFirstStep =
    params.step === "log-in" || params.step === "sign-up" ? true : false;

  const handleLogout = async () => {
    await fetch(`${API_URL}/logout`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).catch((error) => {
      console.error("Error:", error);
    });
    setInLocalStorage("periFormData", {});
  };

  return (
    <Box>
      <Box
        sx={{
          height: isFirstStep ? "100vh" : "",
          background: isFirstStep
            ? "linear-gradient(180deg, #F8CFB1, #EEEAE2)"
            : "",
        }}
      >
        <ContentContainer>
          <NavElementLink to="/">Home</NavElementLink>
          {!isFirstStep && (
            <NavElementLink
              to="/"
              onClick={handleLogout}
              sx={{ marginLeft: "32px" }}
            >
              Logout
            </NavElementLink>
          )}
        </ContentContainer>
        {!isFirstStep && (
          <ContentContainer sx={{ paddingTop: "0px" }}>
            <BreadCrumbs />
          </ContentContainer>
        )}
        {isFirstStep && <PeriLogin />}
        {params.step === PERI_STEPS.ADD_COMPANY && <AddCompany />}
        {params.step === PERI_STEPS.CALCULATE && <Calculate />}
        {params.step === PERI_STEPS.EVALUATE && <Evaluate />}
        {params.step === PERI_STEPS.SIMULATE && <Simulate />}
        {params.step === PERI_STEPS.COMING_SOON && <Box>COMING_SOON</Box>}
      </Box>
      <Box sx={{ height: "300px" }}></Box>
      <Footer />
    </Box>
  );
};

export default PeRiWizard;
