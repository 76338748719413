import { styled, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const Title = styled("h1")(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    font-size: 50px;
    font-weight: bold;
    span { 
      font-weight: normal;
    }
    .accent {
      font-weight: bold;
      color: ${theme.palette.primary.contrastText};
      text-transform: capitalize;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
          `
);
export const SectionTitle = styled("h2")(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    font-size: 28px;
          `
);

export const SubTitle = styled("h3")(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
    font-size: 28px;
    color: ${theme.palette.primary.contrastText};
          `
);

export const ContentContainer = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(9)};
  margin: auto;
  max-width: 1200px;
  @media (max-width: 768px) {
    width: 90%;
    padding: 0px;
    padding-top: ${theme.spacing(5)};
    padding-bottom: ${theme.spacing(5)};
  }
          `
);

export const ContentCard = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  height: 100%;
  border-radius: 20px;
  width: 75%;
  background-color: ${theme.palette.background.paper};
  font-size: 24px;
  font-weight: 600;
  color: ${theme.palette.common.white};
    span {
      font-size: 24px;
      color: ${theme.palette.common.black};
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    `
);

export const ScoreCard = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  height: 100%;
  border-radius: 20px;
  width: 70%;
  background-color: ${theme.palette.common.white};
  font-size: 24px;
  color: ${theme.palette.common.black};
    span {
      font-size: 24px;
      font-weight: bold;
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    `
);

export const TextBlock = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
  font-size: 24px;
  width: 50%;
  span {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
    `
);

export const Text = styled(Box)(
  ({ theme }) => `
  font-size: 24px;
  color: ${theme.palette.common.white};
    `
);

export const HowItWorksTile = styled("h3")(
  ({ theme }) => `
  color: black;
  margin-bottom: ${theme.spacing(2)};
  font-size: 28px;
  height: 100px;
          `
);
export const HowItWorksBody = styled("p")(
  ({ theme }) => `
  color: black;
  margin-bottom: ${theme.spacing(2)};
  font-size: 21px;
          `
);

export const NavElementLink = styled(RouterLink)(
  ({ theme }) => `
  cursor: pointer;
  color: ${theme.palette.common.black};
  text-decoration: none;
  transition: 0.3s ease;
    &:hover {
    text-decoration: underline;
    }
        `
);

export const BigLink = styled(RouterLink)(
  ({ theme }) => `
  cursor: pointer;
  font-size: 50px;
  text-decoration: none;
  color: ${theme.palette.common.black};
  font-weight: normal;
  transition: 0.3s ease;
    &:hover {
    text-decoration: underline;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
        `
);

export const BreadCrumbButton = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== "completed",
})(
  ({ theme, completed }: { completed: boolean; theme: any }) => `
  cursor: pointer;
  padding: ${theme.spacing(1, 3)};
  width: fit-content;
  background-color: ${theme.palette.background.paper};
  border-radius: 20px;
  color: ${completed ? theme.palette.common.black : theme.palette.common.white};
          `
);
