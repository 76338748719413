import { useTheme, Box, styled } from "@mui/material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PieChartIcon from "@mui/icons-material/PieChart";
import {
  SectionTitle,
  ContentContainer,
  RadarChart,
  Title,
  Text,
} from "../../components";
import { MEMBER_TYPE } from "../../constants";
export const ExampleScore = () => {
  const theme = useTheme();

  const radarChartData = [
    ["age", 0.78],
    ["highest_degree", 0.69],
    ["num_companies_worked", 0.82],
    ["num_industries_worked", 0.75],
    ["num_othe_boards", 0.35],
    ["primary_industry_focus", 0.82],
    ["race_ethnicity", 0.78],
    ["region_of_education_college", 0.44],
    ["region_of_education_highest_degree", 0.89],
    ["sex", 0.69],
    ["subject_of_study_college", 0.82],
    ["subject_of_study_highest_degree", 0.42],
    ["years_on_board", 0.82],
  ];

  const isMobile = window.innerWidth < 500;

  return (
    <>
      <Box sx={{ background: theme.palette.common.black }}>
        <ContentContainer sx={{ maxWidth: "1000px" }}>
          <Title
            sx={{
              color: theme.palette.primary.contrastText,
              textAlign: "left",
              marginBottom: theme.spacing(2),
            }}
          >
            A Real World Example
          </Title>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
              marginBottom: "100px",
            }}
          >
            <Text
              sx={{
                textAlign: "left",
                width: "700px",
                marginBottom: theme.spacing(7),
                color: theme.palette.common.white,
                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              This is Walmart's Board of Directors' Diversity Report from its
              2023 Annual Proxy Filing.
            </Text>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: theme.spacing(8),
                "@media (max-width: 768px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Box
                sx={{
                  padding: theme.spacing(3),
                  background: theme.palette.background.default,
                }}
              >
                <WalmartInfoImg src="./walmartInfo.png" alt="Walmart" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "@media (max-width: 768px)": {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                >
                  <ThumbDownAltIcon
                    sx={{
                      fontSize: "32px",
                      fill: "red",
                      marginRight: theme.spacing(2),
                    }}
                  />
                  <Text>Complex</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "@media (max-width: 768px)": {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                >
                  <EqualizerIcon
                    sx={{
                      fontSize: "32px",
                      fill: "red",
                      marginRight: theme.spacing(2),
                    }}
                  />
                  <Text>Hard to benchmark</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "@media (max-width: 768px)": {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                >
                  <QueryStatsIcon
                    sx={{
                      fontSize: "32px",
                      fill: "red",
                      marginRight: theme.spacing(2),
                    }}
                  />
                  <Text>Hard to measure progress</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "@media (max-width: 768px)": {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                >
                  <CheckBoxIcon
                    sx={{
                      fontSize: "32px",
                      fill: "red",
                      marginRight: theme.spacing(2),
                    }}
                  />
                  <Text>Feels like “checkbox”</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "@media (max-width: 768px)": {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                >
                  <PieChartIcon
                    sx={{
                      fontSize: "32px",
                      fill: "red",
                      marginRight: theme.spacing(2),
                    }}
                  />
                  <Text>“Quota” creates risk</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </ContentContainer>
      </Box>
      <ContentContainer
        sx={{
          maxWidth: "1000px",
          marginTop: theme.spacing(5),
          marginBottom: theme.spacing(12),
        }}
      >
        <Text
          sx={{
            textAlign: "left",
            color: theme.palette.common.black,
            marginBottom: theme.spacing(8),
          }}
        >
          <Box
            sx={{
              width: "120px",
              height: "2px",
              marginBottom: theme.spacing(3),
              background: theme.palette.background.paper,
            }}
          ></Box>
          Go{" "}
          <span style={{ color: theme.palette.primary.contrastText }}>
            Beyond Diversity
          </span>{" "}
          to solve the “D” part of DEI:
        </Text>
        <Text
          sx={{
            fontWeight: "bold",
            color: theme.palette.primary.contrastText,
            textAlign: "center",
            "@media (max-width: 500px)": {
              marginBottom: theme.spacing(8),
            },
          }}
        >
          PeRi Score of Walmart’s BoD
        </Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: isMobile ? "-125px" : "-50px",
            }}
          >
            <RadarChart
              diversityData={radarChartData}
              marginTopValue="-190px"
              centerLeftValue="48%"
              memberType={MEMBER_TYPE.BOARD}
              topValue={isMobile ? "18.5%" : "31%"}
              heightValue={isMobile ? "600px" : "1000px"}
              widthValue={isMobile ? "600px" : "1000px"}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginTop: "-380px",
              "@media (max-width: 500px)": {
                marginTop: "-300px",
              },
            }}
          >
            <SectionTitle
              sx={{
                color: theme.palette.primary.contrastText,
                marginBottom: theme.spacing(1),
              }}
            >
              Summary
            </SectionTitle>
            <Text
              sx={{
                color: theme.palette.common.black,
              }}
            >
              Walmart’s 2023 Board of Directors has a PeRi Score of 71/100. This
              means the likelihood of having different perspectives between any
              two random board members is 71% based on proxies of their
              background, choices and experience.
            </Text>
            <SectionTitle
              sx={{
                color: theme.palette.primary.contrastText,
                marginBottom: theme.spacing(1),
              }}
            >
              Interpretation
            </SectionTitle>
            <Text
              sx={{
                width: "100%",
                color: theme.palette.common.black,
              }}
            >
              Walmart has 11 Directors on its Board. PeRiScore helps Walmart
              them determine if they have enough differences in perspectives and
              viewpoints to feel confident that their decision-making does not
              face high risk from group-think.
              <br></br>
              <br></br>
              Walmart’s PeRi score indicates a high likelihood of different
              perspectives based on its board members’ careers (primary industry
              focus, # of companies worked, and so on). However, it also
              indicates a low likelihood of different perspectives based on
              inherent traits such as race and sex.
              <br></br>
              <br></br>
              This implies a high risk of blind-spots on decisions around
              markets or opportunities where gender and race/ethnicity factor
              strongly in driving outcomes.
              <br></br>
              <br></br>
              In a few months, you will be able to compare Walmart - and your
              team’s - PeRi Score to a set of US-listed public companies such as
              the Dow30 or Nasdaq100.
              <br></br>
              <br></br>
            </Text>
            <Box
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              Disclaimer: data on the individuals in this assessment are from
              public sources and may contain inaccuracies or be out of date.
            </Box>
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
};

export const WalmartInfoImg = styled("img")(
  ({ theme }) => `
  height: 385px;
  width: 370px;
  box-shadow: -2px 9px 18px -4px rgba(0,0,0,0.75);
  @media (max-width: 768px) {
    height: 300px;
    width: 300px;
  }
    `
);

export default ExampleScore;
