const isProduction = true;

export const STAGING_URL =
  "https://beyond-diversity-staging-f8b89483fa16.herokuapp.com/api/v1";
export const PROD_URL =
  "https://beyond-diversity-production-c91306b5ebea.herokuapp.com/api/v1";

export const API_URL = isProduction ? PROD_URL : STAGING_URL;

export enum API_STATUS {
  LOADING = "LOADING",
  SUCCESS = "success",
  FAILURE = "failure",
}

export enum MEMBER_TYPE {
  BOARD = "board",
  MANAGEMENT = "management",
}

export enum PERI_STEPS {
  ADD_COMPANY = "add-company",
  CALCULATE = "calculate",
  EVALUATE = "evaluate",
  SIMULATE = "simulate",
  COMING_SOON = "coming-soon",
}

// ALL DATA FIELDS
// age
// highest_degree
// member_type
// name
// num_companies_worked
// num_industries_worked
// num_other_boards
// primary_industry_focus
// provisional
// race_ethnicity
// region_of_education_college
// region_of_education_highest_degree
// sex
// subject_of_study_college
// subject_of_study_highest_degree
// year
// years_on_board

// BOARD MEMBER DATA
// Name
// Age
// Sex
// Race/Ethnicity
// # of other boards
// Years on board
// # of Companies Worked
// # of Industries Worked
// Primary Industry Focus
// Highest Degree
// Subject of Study (College)
// Subject of Study (Highest degree)
// Region of Education (College)
// Region of Education (Highest degree)

// MGMT DATA
// Name
// Age
// Sex
// Race/Ethnicity
// # of Companies Worked
// # of Industries Worked
// Highest Degree
// Years on board
// Subject of Study (College)
// Subject of Study (Highest degree)
// Region of Education (College)
// Region of Education (Highest degree)

// DIFF DATA
// # of other boards
// Primary Industry Focus

export const boardHeaderData = [
  { key: "", readOnly: true, value: "" },
  { key: "name", value: "Name", readOnly: true },
  { key: "age", value: "Age", readOnly: true },
  { key: "sex", value: "Sex", readOnly: true },
  { key: "race_ethnicity", value: "Race/Ethnicity", readOnly: true },
  { key: "years_on_board", value: "Tenure", readOnly: true },
  {
    key: "num_companies_worked",
    value: "# of Companies Worked",
    readOnly: true,
  },
  {
    key: "num_industries_worked",
    value: "# of Industries Worked",
    readOnly: true,
  },
  { key: "num_other_boards", value: "# Other Boards", readOnly: true },
  {
    key: "primary_industry_focus",
    value: "Primary Industry Focus",
    readOnly: true,
  },
  { key: "highest_degree", value: "Highest Degree", readOnly: true },
  {
    key: "subject_of_study_college",
    value: "Subject of Study (College)",
    readOnly: true,
  },
  {
    key: "subject_of_study_highest_degree",
    value: "Subject of Study (Highest degree)",
    readOnly: true,
  },
  {
    key: "region_of_education_college",
    value: "Region of Education (College)",
    readOnly: true,
  },
  {
    key: "region_of_education_highest_degree",
    value: "Region of Education (Highest degree)",
    readOnly: true,
  },
];

export const mgmtHeaderData = boardHeaderData.filter(
  (x) => x.key !== "num_other_boards" && x.key !== "primary_industry_focus"
);

export const BOARD_TABLE_DATA = [
  [
    { readOnly: true, value: "" },
    { value: "Name", readOnly: true },
    { value: "Age", readOnly: true },
    { value: "Sex", readOnly: true },
    { value: "Race/Ethnicity", readOnly: true },
    { value: "# of other boards", readOnly: true },
    { value: "Tenure", readOnly: true },
    { value: "# of Companies Worked", readOnly: true },
    { value: "# of Industries Worked", readOnly: true },
    { value: "Primary Industry Focus", readOnly: true },
    { value: "Highest Degree", readOnly: true },
    { value: "Subject of Study (College)", readOnly: true },
    { value: "Subject of Study (Highest degree)", readOnly: true },
    { value: "Region of Education (College)", readOnly: true },
    { value: "Region of Education (Highest degree)", readOnly: true },
  ],
  [
    { readOnly: true, value: "1" },
    { value: "Jane Doe", key: "name" },
    { value: "50", key: "age" },
    { value: "Female", key: "sex" },
    { value: "African American", key: "race_ethnicity" },
    { value: "3", key: "num_other_boards" },
    { value: "10", key: "years_on_board" },
    { value: "4", key: "num_companies_worked" },
    { value: "6", key: "num_industries_worked" },
    { value: "Information Technology", key: "primary_industry_focus" },
    { value: "MBA", key: "highest_degree" },
    { value: "Information Technology", key: "subject_of_study_college" },
    { value: "Finance", key: "subject_of_study_highest_degree" },
    { value: "USA", key: "region_of_education_college" },
    { value: "USA", key: "region_of_education_highest_degree" },
  ],
  [
    { readOnly: true, value: "2" },
    { value: "John Doe", key: "name" },
    { value: "45", key: "age" },
    { value: "Male", key: "sex" },
    { value: "White", key: "race_ethnicity" },
    { value: "5", key: "num_other_boards" },
    { value: "1", key: "years_on_board" },
    { value: "9", key: "num_companies_worked" },
    { value: "1", key: "num_industries_worked" },
    { value: "Finance", key: "primary_industry_focus" },
    { value: "MBA", key: "highest_degree" },
    { value: "Finance", key: "subject_of_study_college" },
    { value: "Finance", key: "subject_of_study_highest_degree" },
    { value: "USA", key: "region_of_education_college" },
    { value: "USA", key: "region_of_education_highest_degree" },
  ],
];

export const BOARD_TABLE_HEADERS = [
  [
    { readOnly: true, value: "" },
    { value: "Name", readOnly: true },
    { value: "Age", readOnly: true },
    { value: "Sex", readOnly: true },
    { value: "Race/Ethnicity", readOnly: true },
    { value: "# of other boards", readOnly: true },
    { value: "Tenure", readOnly: true },
    { value: "# of Companies Worked", readOnly: true },
    { value: "# of Industries Worked", readOnly: true },
    { value: "Primary Industry Focus", readOnly: true },
    { value: "Highest Degree", readOnly: true },
    { value: "Subject of Study (College)", readOnly: true },
    { value: "Subject of Study (Highest degree)", readOnly: true },
    { value: "Region of Education (College)", readOnly: true },
    { value: "Region of Education (Highest degree)", readOnly: true },
  ],
];

export const MGMT_TABLE_HEADERS = [
  [
    { readOnly: true, value: "" },
    { value: "Name", readOnly: true },
    { value: "Age", readOnly: true },
    { value: "Sex", readOnly: true },
    { value: "Race/Ethnicity", readOnly: true },
    { value: "Tenure", readOnly: true },
    { value: "# of Companies Worked", readOnly: true },
    { value: "# of Industries Worked", readOnly: true },
    { value: "Highest Degree", readOnly: true },
    { value: "Subject of Study (College)", readOnly: true },
    { value: "Subject of Study (Highest degree)", readOnly: true },
    { value: "Region of Education (College)", readOnly: true },
    { value: "Region of Education (Highest degree)", readOnly: true },
  ],
];

export const MGMT_TABLE_DATA = [
  [
    { readOnly: true, value: "" },
    { value: "Name", readOnly: true },
    { value: "Age", readOnly: true },
    { value: "Sex", readOnly: true },
    { value: "Race/Ethnicity", readOnly: true },
    { value: "Tenure", readOnly: true },
    { value: "# of Companies Worked", readOnly: true },
    { value: "# of Industries Worked", readOnly: true },
    { value: "Highest Degree", readOnly: true },
    { value: "Subject of Study (College)", readOnly: true },
    { value: "Subject of Study (Highest degree)", readOnly: true },
    { value: "Region of Education (College)", readOnly: true },
    { value: "Region of Education (Highest degree)", readOnly: true },
  ],
  [
    { readOnly: true, value: "1" },
    { value: "Jane Doe", key: "name" },
    { value: "50", key: "age" },
    { value: "Female", key: "sex" },
    { value: "African American", key: "race_ethnicity" },
    { value: "10", key: "years_on_board" },
    { value: "4", key: "num_companies_worked" },
    { value: "6", key: "num_industries_worked" },
    { value: "MBA", key: "highest_degree" },
    { value: "Information Technology", key: "subject_of_study_college" },
    { value: "Finance", key: "subject_of_study_highest_degree" },
    { value: "USA", key: "region_of_education_college" },
    { value: "USA", key: "region_of_education_highest_degree" },
  ],
  [
    { readOnly: true, value: "2" },
    { value: "John Doe", key: "name" },
    { value: "45", key: "age" },
    { value: "Male", key: "sex" },
    { value: "White", key: "race_ethnicity" },
    { value: "10", key: "years_on_board" },
    { value: "9", key: "num_companies_worked" },
    { value: "1", key: "num_industries_worked" },
    { value: "MBA", key: "highest_degree" },
    { value: "Finance", key: "subject_of_study_college" },
    { value: "Finance", key: "subject_of_study_highest_degree" },
    { value: "USA", key: "region_of_education_college" },
    { value: "USA", key: "region_of_education_highest_degree" },
  ],
];

export const keysOrder = [
  "name",
  "age",
  "sex",
  "race_ethnicity",
  "years_on_board",
  "num_companies_worked",
  "num_industries_worked",
  "num_other_boards",
  "primary_industry_focus",
  "highest_degree",
  "subject_of_study_college",
  "subject_of_study_highest_degree",
  "region_of_education_college",
  "region_of_education_highest_degree",
];

export const boardRadarLabels = [
  "Age",
  "Highest Degree",
  "# Companies Worked",
  "# Industries Worked",
  "# Other Boards",
  "Primary Industry Focus",
  "Race / Ethnicity",
  "Region of Study (College)",
  "Subject of Study (Highest ED)",
  "Sex",
  "Subject of Study (College)",
  "Region of Study (Highest ED)",
  "Tenure",
];

export const mgmtRadarLabels = [
  "Age",
  "Highest Degree",
  "# Companies Worked",
  "# Industries Worked",
  "Race / Ethnicity",
  "Region of Study (College)",
  "Subject of Study (Highest ED)",
  "Sex",
  "Subject of Study (College)",
  "Region of Study (Highest ED)",
  "Tenure",
];
