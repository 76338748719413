import { Box, useTheme } from "@mui/material";
import { RechartRadarChart } from "./rechart-radar-chart";
import { calculateAverage } from "../utils";
import { MEMBER_TYPE, boardRadarLabels, mgmtRadarLabels } from "../constants";

interface RadarChartProps {
  diversityData: any[];
  marginTopValue?: string;
  topValue?: string;
  widthValue?: string;
  heightValue?: string;
  centerLeftValue?: string;
  marginLeftValue?: string;
  memberType?: MEMBER_TYPE;
}

export const RadarChart = ({
  diversityData,
  marginTopValue,
  topValue,
  widthValue,
  heightValue,
  centerLeftValue,
  marginLeftValue,
  memberType,
}: RadarChartProps) => {
  const theme = useTheme();
  // @ts-ignore
  const formattedChartData = diversityData.map((x) => x[1]) as number[];

  // OLD DATA SET
  // age;
  // highest_degree;
  // num_companies_worked;
  // num_industries_worked;
  // race_ethnicity;
  // region_of_education_college;
  // region_of_education_highest_degree;
  // sex;
  // subject_of_study_college;
  // subject_of_study_highest_degree;
  // years_on_board;

  // NEW DATA SET
  // age,
  // highest_degree,
  // num_companies_worked,
  // num_industries_worked,

  // num_other_boards,

  // primary_industry_focus,

  // race_ethnicity,
  // region_of_education_college,
  // region_of_education_highest_degree,
  // sex,
  // subject_of_study_college,
  // subject_of_study_highest_degree,
  // years_on_boar

  const labels =
    memberType === MEMBER_TYPE.BOARD ? boardRadarLabels : mgmtRadarLabels;

  const formatData = (diversityData: number[]) => {
    return diversityData.map((value, index) => {
      return {
        subject: labels[index],
        A: value,
        fullMark: 150,
      };
    });
  };
  const data = formatData(formattedChartData);

  return (
    <Box
      sx={{
        position: "relative",
        width: widthValue,
        height: heightValue,
        margin: "auto",
        zIndex: 1,
        ".recharts-text": {
          fontSize: "14px",
        },
        svg: {
          overflow: "visible",
          // TODO: marginTop Make a prop
          marginTop: marginTopValue || "0px",
          marginLeft: marginLeftValue ? marginLeftValue : "-35px",
          zIndex: 4,
        },
      }}
    >
      <Box
        sx={{
          fontSize: "40px",
          fontWeight: "bold",
          position: "absolute",
          top: topValue || "49.5%",
          left: centerLeftValue || "47%",
          zIndex: 1,
          transform: "translate(-50%, -50%)",
          backgroundColor: theme.palette.background.default,
          borderRadius: "50%",
          width: "60px",
          height: "60px",
        }}
      ></Box>
      <Box
        sx={{
          fontSize: "40px",
          fontWeight: "bold",
          position: "absolute",
          top: topValue || "49.5%",
          left: centerLeftValue || "47%",
          zIndex: 5,
          transform: "translate(-50%, -50%)",
          color: theme.palette.primary.contrastText,
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          background: "rgba(238, 96, 62, 0.2)",
          textAlign: "center",
        }}
      >
        {calculateAverage(formattedChartData)}
      </Box>
      <RechartRadarChart data={data} />
    </Box>
  );
};

export default RadarChart;
