import { useTheme, Box, styled, Button } from "@mui/material";
import Datasheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { useEffect, useState } from "react";
import {
  API_URL,
  PERI_STEPS,
  BOARD_TABLE_DATA,
  MEMBER_TYPE,
  MGMT_TABLE_DATA,
} from "../constants";
import {
  getFromLocalStorage,
  formatSubmitPayload,
  setInLocalStorage,
  formatBoardDataToTableData,
  getNewTableRow,
} from "../utils";
import { useNavigate } from "react-router-dom";

interface DataTableProps {
  provisionalTable: boolean;
  memberType: MEMBER_TYPE;
  onSubmitCallback?: any;
}

export const DataTable = ({
  provisionalTable,
  memberType,
  onSubmitCallback,
}: DataTableProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const periFormData = getFromLocalStorage("periFormData");
  const defaultTableData =
    memberType === MEMBER_TYPE.BOARD ? BOARD_TABLE_DATA : MGMT_TABLE_DATA;
  const [tableBoardData, setBoardTableData] = useState<any>(BOARD_TABLE_DATA);
  const [tableMGMTData, setMGMTTableData] = useState<any>(MGMT_TABLE_DATA);
  const [allTableData, setAllTableData] = useState<any[]>([]);
  const [disableNext, setDisableNext] = useState(true);
  const tableData =
    memberType === MEMBER_TYPE.BOARD ? tableBoardData : tableMGMTData;
  const setTableData =
    memberType === MEMBER_TYPE.BOARD ? setBoardTableData : setMGMTTableData;

  useEffect(() => {
    if (memberType && allTableData.length > 0) {
      if (allTableData.find((data: any) => data.member_type === memberType)) {
        const formattedData = formatBoardDataToTableData(
          allTableData.filter((data: any) => data.member_type === memberType),
          memberType,
          provisionalTable
        );
        const result =
          formattedData.length > 1 ? formattedData : defaultTableData;
        setTableData(result);
      } else {
        setTableData(defaultTableData);
      }
    }
  }, [
    memberType,
    allTableData,
    provisionalTable,
    defaultTableData,
    setTableData,
  ]);

  useEffect(() => {
    fetchBoardData();
  }, []);

  const fetchBoardData = async () => {
    try {
      const boardMemeberResp = await fetch(
        `${API_URL}/board-members/${periFormData.company_id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const boardMemberData = await boardMemeberResp.json();
      if (boardMemberData.board_members.length > 0) {
        const testOne = boardMemberData.board_members.filter(
          (data: any) => data.member_type === memberType
        );
        const formattedData = formatBoardDataToTableData(
          testOne,
          memberType,
          provisionalTable
        );
        const result = formattedData ? formattedData : defaultTableData;
        setTableData(result);
        setAllTableData(boardMemberData.board_members);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    const formattedGridData = formatSubmitPayload(
      tableData,
      allTableData,
      periFormData.company_id,
      memberType || "board",
      provisionalTable
    );
    try {
      await fetch(`${API_URL}/add-board-members/${periFormData.company_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(formattedGridData),
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      if (onSubmitCallback) {
        onSubmitCallback();
      }
      fetchBoardData();
    }
  };

  const handleCellsChanged = (changes: any) => {
    const updatedGrid = tableData ? [...tableData] : []; // Create a copy of the grid state
    //@ts-ignore
    changes.forEach(({ row, col, value }) => {
      updatedGrid[row][col] = { ...updatedGrid[row][col], value };
    });
    setTableData(tableData);
  };

  const addRow = () => {
    const newRow = getNewTableRow(memberType, tableData);
    setTableData([...tableData, newRow]);
  };

  const removeRow = async (event: any) => {
    event?.preventDefault();
    const lastElement = tableData[tableData.length - 1];
    const boardMemberId = lastElement[0].value;
    if (lastElement[1].value === "") {
      setTableData(tableData.slice(0, tableData.length - 1));
    } else {
      try {
        await fetch(
          `${API_URL}/delete-board-member/${periFormData.company_id}/${boardMemberId}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
          }
        );
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setTableData(tableData.slice(0, tableData.length - 1));
        if (onSubmitCallback) {
          onSubmitCallback();
          fetchBoardData();
        } else {
          fetchBoardData();
        }
      }
    }
  };

  useEffect(() => {
    if (
      allTableData.find((data: any) => data.member_type) &&
      disableNext === true
    ) {
      setDisableNext(false);
    }
  }, [allTableData, disableNext, setDisableNext]);

  const handleNext = () => {
    const hasBoardData = allTableData.find(
      (data) => data.member_type === MEMBER_TYPE.BOARD
    );
    const hasMGMTData = allTableData.find(
      (data) => data.member_type === MEMBER_TYPE.MANAGEMENT
    );

    if (hasBoardData && hasMGMTData) {
      setInLocalStorage("periFormData", {
        ...periFormData,
        singleMemberType: undefined,
        step: PERI_STEPS.EVALUATE,
      });
      navigate(`/peri/${PERI_STEPS.EVALUATE}`);
    } else if (hasBoardData && !hasMGMTData) {
      setInLocalStorage("periFormData", {
        ...periFormData,
        step: PERI_STEPS.EVALUATE,
        singleMemberType: hasBoardData
          ? MEMBER_TYPE.BOARD
          : MEMBER_TYPE.MANAGEMENT,
        memberType: MEMBER_TYPE.BOARD,
      });
      navigate(`/peri/${PERI_STEPS.EVALUATE}`);
    } else if (!hasBoardData && hasMGMTData) {
      setInLocalStorage("periFormData", {
        ...periFormData,
        step: PERI_STEPS.EVALUATE,
        singleMemberType: hasBoardData
          ? MEMBER_TYPE.BOARD
          : MEMBER_TYPE.MANAGEMENT,
        memberType: MEMBER_TYPE.MANAGEMENT,
      });
      navigate(`/peri/${PERI_STEPS.EVALUATE}`);
    }
  };

  return (
    <>
      <TableContainer
        className="sheet-container"
        sx={{ display: memberType === MEMBER_TYPE.BOARD ? "block" : "none" }}
      >
        <Datasheet
          data={tableBoardData}
          // @ts-ignore
          valueRenderer={(cell) => cell.value}
          onContextMenu={(e, cell, i, j) =>
            cell.readOnly ? e.preventDefault() : null
          }
          onCellsChanged={handleCellsChanged}
        />
      </TableContainer>
      <TableContainer
        className="sheet-container"
        sx={{
          display: memberType === MEMBER_TYPE.MANAGEMENT ? "block" : "none",
        }}
      >
        <Datasheet
          data={tableMGMTData}
          // @ts-ignore
          valueRenderer={(cell) => cell.value}
          onContextMenu={(e, cell, i, j) =>
            cell.readOnly ? e.preventDefault() : null
          }
          onCellsChanged={handleCellsChanged}
        />
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "@media (max-width: 768px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            "@media (max-width: 768px)": {
              flexDirection: "column",
            },
          }}
        >
          <Button onClick={addRow} sx={{ color: theme.palette.common.black }}>
            Add Row
          </Button>
          <Button
            onClick={removeRow}
            sx={{ color: theme.palette.common.black }}
          >
            Remove Row
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={(event) => handleSubmit(event)}
            sx={{
              marginLeft: theme.spacing(3),
              background: theme.palette.background.paper,
              color: theme.palette.common.white,
              "@media (max-width: 768px)": {
                marginLeft: "0px",
              },
            }}
          >
            Submit
          </Button>
          {!provisionalTable && (
            <Button
              type="submit"
              variant="contained"
              onClick={handleNext}
              disabled={disableNext}
              sx={{
                marginLeft: theme.spacing(3),
                background: theme.palette.background.paper,
                color: theme.palette.common.white,
                "@media (max-width: 768px)": {
                  marginLeft: "0px",
                },
              }}
            >
              NEXT STEP
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

const TableContainer = styled(Box)(
  ({ theme }) => `
  display: block;
  overflow: scroll;
  margin-bottom: ${theme.spacing(3)};
  table.data-grid {
    margin: auto;
    width: 100%;
  }
    .data-grid { 
      width: 100%;
      background: white;
      tr td:first-child {
        display: none;
      }
      .cell {
        min-width: 200px;
        width: 300px;
        white-space: nowrap;
        span {
          min-height: 30px;
        }
        .value-viewer {
          display: flex;
          justify-content: end;
          align-items: center;
        }
      }
      .data-editor {
        height: 100% !important;
        width: 100% !important;
        font-size: 1rem;
      }
    }

          `
);

export default DataTable;
