import { useTheme, Box } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Title, SectionTitle, ContentContainer } from "../../components";
import { getFromLocalStorage, setInLocalStorage } from "../../utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, PERI_STEPS } from "../../constants";

export const AddCompany = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    industry: "",
    name: "",
  });
  const periFormData = getFromLocalStorage("periFormData");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleNext = async (event: any) => {
    event?.preventDefault();
    try {
      const response = await fetch(`${API_URL}/add-company`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      const jsonData = await response.json();
      setInLocalStorage("periFormData", {
        ...periFormData,
        company_name: formData.name,
        industry: formData.industry,
        company_id: jsonData.company_id,
        step: PERI_STEPS.CALCULATE,
      });
      navigate("/peri/calculate");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ContentContainer sx={{ paddingTop: "0px" }}>
        <Title
          sx={{
            marginTop: "0px",
            textTransform: "capitalize",
            color: theme.palette.primary.contrastText,
          }}
        >
          Add Company
        </Title>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(8),
            marginTop: theme.spacing(5),
            "@media (max-width: 768px)": {
              flexDirection: "column-reverse",
              gap: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              "@media (max-width: 768px)": {
                width: "100%",
              },
            }}
          >
            <TextField
              label="Company Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={{ marginBottom: "20px" }}
              required
            />
            <TextField
              label="Industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              sx={{ marginBottom: "20px" }}
              required
            />
            <Button
              type="submit"
              variant="contained"
              disabled={!formData.name || !formData.industry}
              onClick={handleNext}
              sx={{
                width: "100%",
                fontSize: "20px",
                background: theme.palette.background.paper,
                color: theme.palette.common.white,
                marginTop: theme.spacing(2),
              }}
            >
              Next
            </Button>
          </Box>
          <SectionTitle
            sx={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "0px",
              marginBottom: "0px",
              "@media (max-width: 768px)": {
                width: "100%",
                marginBottom: theme.spacing(3),
              },
            }}
          >
            Create your company profile.
          </SectionTitle>
        </Box>
      </ContentContainer>
    </>
  );
};

export default AddCompany;
