import { Box, styled, useTheme } from "@mui/material";
import { ContentContainer } from "./shared-elements";

export const Footer = () => {
  const theme = useTheme();
  return (
    <FooterContainer>
      <ContentContainer sx={{ textAlign: "center" }}>
        <Box sx={{ fontSize: "12px", color: theme.palette.common.white }}>
          © 2024 Beyond Diversity
        </Box>
      </ContentContainer>
    </FooterContainer>
  );
};

export const FooterContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    width: 100%;
    height: 250px;
    background-color: ${theme.palette.common.black};
    bottom: 0;
            `
);

export default Footer;
