import { useTheme, Box, styled } from "@mui/material";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { SubTitle, ContentContainer, Title } from "../../components";
// @ts-ignore
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Link } from "react-router-dom";
import { getFromLocalStorage } from "../../utils";

interface SignUpFormProps {
  onValidated: (formData: any) => void;
  status: string | null;
  message: string | Error | null;
}

const SignUpForm = ({ onValidated, status, message }: SignUpFormProps) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    linkedin: "",
    firstAndLast: "",
    email: "",
  });

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      formData.email &&
      formData.firstAndLast &&
      formData.linkedin &&
      formData.email
    ) {
      onValidated({
        MERGE0: formData.email,
        MERGE1: formData.linkedin,
        MERGE2: formData.firstAndLast,
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          height: theme.spacing(3),
          marginBottom: theme.spacing(2),
        }}
      >
        {status === "sending" && (
          <Box sx={{ color: theme.palette.primary.contrastText }}>
            ...pending
          </Box>
        )}
        {status === "error" && (
          <Box sx={{ color: "red" }}>An error has occured</Box>
        )}
        {status === "success" && (
          <Box sx={{ color: theme.palette.primary.contrastText }}>
            You are successfully subscribed!
          </Box>
        )}
      </Box>

      {status !== "success" && (
        <Form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            label="First & Last Name"
            name="firstAndLast"
            value={formData.firstAndLast}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            disabled={status === "sending"}
            required
          />
          <TextField
            label="Work Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            disabled={status === "sending"}
            required
          />
          <TextField
            label="Linkedin / Website"
            name="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            required
            disabled={status === "sending"}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={
              status === "sending" ||
              formData.firstAndLast === "" ||
              formData.email === "" ||
              formData.linkedin === ""
            }
            sx={{
              width: "150px",
              background: theme.palette.background.paper,
              color: theme.palette.common.white,
            }}
          >
            Submit
          </Button>
        </Form>
      )}
    </Box>
  );
};

export const MailChimpSignUp = () => {
  const theme = useTheme();
  const periFormData = getFromLocalStorage("periFormData");
  const postUrl = `https://relativediversity.us12.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&amp;id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <Wrapper>
      <Box
        sx={{
          background: theme.palette.background.paper,
          "@media (max-width: 768px)": {
            paddingTop: "50px",
            paddingBottom: "50px",
          },
        }}
      >
        <ContentContainer
          sx={{
            height: "100%",
            paddingTop: "200px",
            paddingBottom: "200px",
            marginTop: "0px",
            marginBottom: "100px",
          }}
        >
          <Title
            sx={{
              marginBottom: "10px",
              textAlign: "center",
              color: theme.palette.common.white,
            }}
          >
            What's your team's <br />
            <span
              style={{ color: theme.palette.primary.dark, fontWeight: "bold" }}
            >
              Pe
            </span>
            rspective{" "}
            <span
              style={{ color: theme.palette.primary.dark, fontWeight: "bold" }}
            >
              Ri
            </span>
            sk?{" "}
          </Title>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: theme.spacing(4),
              marginTop: theme.spacing(8),
              "@media (max-width: 768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Link to={"/peri/log-in"}>
              <Button
                variant="contained"
                sx={{
                  width: "350px",
                  padding: theme.spacing(1),
                  fontSize: "24px",
                  borderRadius: "25px",
                  background: theme.palette.background.default,
                  textTransform: "none",
                  color: theme.palette.primary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.background.default,
                  },
                  "@media (max-width: 768px)": {
                    width: "100%",
                  },
                }}
              >
                Calculate my PeRi Score
              </Button>
            </Link>
            <Button
              variant="contained"
              target="_blank"
              href="https://drive.google.com/file/d/1j7wzhmviTaLTydFfDJ93D3s5QJAEw4vP/view?usp=sharing"
              sx={{
                width: "350px",
                padding: theme.spacing(1),
                fontSize: "24px",
                borderRadius: "25px",
                textTransform: "none",
                background: theme.palette.background.default,
                color: theme.palette.primary.dark,
                "&:hover": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.background.default,
                },
                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              Read the White Paper
            </Button>
          </Box>
        </ContentContainer>
      </Box>
      <ContentContainer sx={{ height: "700px" }}>
        <FlexWrapper>
          <Box>
            <Title
              sx={{
                marginBottom: "10px",
                color: theme.palette.primary.contrastText,
              }}
            >
              Join the waitlist
            </Title>
            <MailchimpSubscribe
              url={postUrl}
              render={({ subscribe, status, message }) => (
                <SignUpForm
                  onValidated={(formData) => subscribe(formData)}
                  status={status}
                  message={message}
                />
              )}
            />
          </Box>
          <SubTitleWrapper>
            <SubTitle sx={{ color: theme.palette.common.black }}>
              We're recruiting our pilot cohort of customers. Help us evolve DEI
              beyond demographics, and towards diverse perspectives.
            </SubTitle>
          </SubTitleWrapper>
        </FlexWrapper>
      </ContentContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(
  ({ theme }) => `
  @media (max-width: 768px) {
    margin-bottom: 300px;
  },
  
          `
);

const SubTitleWrapper = styled(Box)(
  ({ theme }) => `
  flex: 1;
  border-left: 1px solid ${theme.palette.primary.contrastText};
  padding-left: ${theme.spacing(4)};
  margin-left: ${theme.spacing(5)};
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding-left: 0px;
    margin-left: 0px;
    border-left: none;
  }
  
          `
);

const FlexWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }`
);

const Form = styled("form")(
  ({ theme }) => `
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 450px;
    @media (max-width: 768px) {
      width: 100%;
    }
          `
);

export default MailChimpSignUp;
