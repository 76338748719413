import { useTheme, Box } from "@mui/material";
import { ContentContainer, Title, Toggle, DataTable } from "../../components";
import "react-datasheet/lib/react-datasheet.css";
import { useEffect, useState } from "react";
import { MEMBER_TYPE } from "../../constants";

export const Calculate = () => {
  const theme = useTheme();
  const [option, setOption] = useState<number>(0);
  const handleTogge = (event: React.SyntheticEvent, newValue: number) => {
    setOption(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContentContainer sx={{ marginBottom: "300px" }}>
        <Title
          sx={{
            textTransform: "capitalize",
            color: theme.palette.primary.contrastText,
            marginBottom: theme.spacing(5),
            marginTop: "0px",
          }}
        >
          Calculate Your PeRi Score
        </Title>
        <Box>
          <Box sx={{ fontSize: "16px", marginBottom: theme.spacing(2) }}>
            Analyze your company’s demographic and lived-experience to quantify
            your Perspective Risk Score.
          </Box>
          <Box sx={{ fontSize: "16px", marginBottom: theme.spacing(2) }}>
            In the table below, input information on your team including age,
            sex, and ethnicity/race, as well as lived-experience including
            education (institutions, degrees and subjects of study) and career
            (number of companies and industries). If evaluating the board, you
            will also need board tenure and number of directorships at other
            public companies.
          </Box>
          <Box
            sx={{
              fontStyle: "italic",
              fontSize: "16px",
              marginBottom: theme.spacing(7),
            }}
          >
            Note: Beyond Diversity’s PeRi Score evaluation is currently limited
            to company Board and Management teams.
          </Box>
          <Toggle selectedOption={option} onChange={handleTogge} />
        </Box>
        <DataTable
          memberType={option === 0 ? MEMBER_TYPE.BOARD : MEMBER_TYPE.MANAGEMENT}
          provisionalTable={false}
        />
      </ContentContainer>
    </>
  );
};

export default Calculate;
