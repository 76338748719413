import { useTheme, Box, Button } from "@mui/material";
import {
  SubTitle,
  ContentContainer,
  SectionTitle,
  Title,
  RadarChart,
  Toggle,
} from "../../components";
import {
  calculateAverage,
  getCurrentDate,
  getFromLocalStorage,
  setInLocalStorage,
} from "../../utils";
import { useEffect, useState } from "react";
import { API_URL, MEMBER_TYPE, PERI_STEPS } from "../../constants";
import { useNavigate } from "react-router-dom";

export const Evaluate = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentDate = getCurrentDate();
  const periFormData = getFromLocalStorage("periFormData");
  const [periScore, setPeriScore] = useState<number>(0);
  const [radarChartData, setRadarChartData] = useState<any[]>([]);
  const [option, setOption] = useState<number>(0);
  const currentType = periFormData.singleMemberType
    ? periFormData.singleMemberType
    : option === 0
    ? MEMBER_TYPE.BOARD
    : MEMBER_TYPE.MANAGEMENT;

  const handleTogge = (event: React.SyntheticEvent, newValue: number) => {
    setOption(newValue);
    fetchPeRiScore(newValue);
    if (option === 0 && periScore > 0) {
      setInLocalStorage("periFormData", {
        ...periFormData,
        [MEMBER_TYPE.BOARD]: { periScore: periScore },
      });
    } else if (option === 1 && periScore > 0) {
      setInLocalStorage("periFormData", {
        ...periFormData,
        [MEMBER_TYPE.MANAGEMENT]: {
          periScore: periScore,
        },
      });
    }
  };
  const isMobile = window.innerWidth < 500;

  const fetchPeRiScore = async (toggleOption?: number) => {
    const memberType = periFormData.singleMemberType
      ? periFormData.singleMemberType
      : toggleOption && toggleOption === 1
      ? MEMBER_TYPE.MANAGEMENT
      : MEMBER_TYPE.BOARD;
    try {
      const response = await fetch(
        `${API_URL}/scores/${periFormData.company_id}?includeProvisional=false&member_type=${memberType}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const periScoreData = await response.json();
      const chartData = Object.entries(periScoreData).filter(
        (x, i) =>
          x[0] !== "company_id" &&
          x[0] !== "provisional" &&
          x[0] !== "member_type" &&
          x[0] !== "year"
      );

      const periNumberData = chartData.map((x) => x[1]) as number[];

      setRadarChartData(chartData);
      setPeriScore(calculateAverage(periNumberData));

      // TODO: REMOVE THIS WHEN MGMT IS READY
      if (calculateAverage(periNumberData) > 0) {
        setInLocalStorage("periFormData", {
          ...periFormData,
          [memberType]: { periScore: calculateAverage(periNumberData) },
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchPeRiScore();
  }, []);

  const handleNextStep = () => {
    setInLocalStorage("periFormData", {
      ...periFormData,
      step: PERI_STEPS.SIMULATE,
    });
    navigate(`/peri/${PERI_STEPS.SIMULATE}`);
  };

  return (
    <ContentContainer>
      <Box>
        <Title
          sx={{
            textAlign: "center",
            color: theme.palette.primary.contrastText,
            marginBottom: "0px",
            span: {
              color: theme.palette.common.black,
            },
          }}
        >
          <span>{`${periFormData.company_name}'s ${currentType}'s `}</span> PeRi
          Score: {`${periScore}`}
        </Title>
        <SectionTitle
          sx={{
            textAlign: "center",
            fontWeight: 400,
            "@media (max-width: 500px)": {
              marginBottom: "110px",
            },
          }}
        >
          {`Calculated by ${periFormData.username} for ${periFormData.company_name} on ${currentDate}`}
        </SectionTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%", marginLeft: isMobile ? "-135px" : "0px" }}>
            <RadarChart
              memberType={
                periFormData.singleMemberType
                  ? periFormData.singleMemberType
                  : option === 0
                  ? MEMBER_TYPE.BOARD
                  : MEMBER_TYPE.MANAGEMENT
              }
              diversityData={
                option === 0
                  ? radarChartData
                  : radarChartData.filter(
                      (x, i) =>
                        x[0] !== "num_other_boards" &&
                        x[0] !== "primary_industry_focus"
                    )
              }
              marginTopValue="-170px"
              topValue={isMobile ? "21.5%" : "34.5%"}
              heightValue={isMobile ? "600px" : "1100px"}
              widthValue={isMobile ? "600px" : "1100px"}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "-384px",
          "@media (max-width: 500px)": {
            marginTop: "-280px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SubTitle>Summary</SubTitle>
          {!periFormData.singleMemberType && (
            <Toggle selectedOption={option} onChange={handleTogge} />
          )}
        </Box>

        <Box sx={{ fontSize: "16px", marginBottom: theme.spacing(3) }}>
          {`Perspective Risk Score - or PeRi Score - quantifies the likelihood that two members of a group have different perspectives based on proxies of their background, choices and experiences.`}
          <br></br>
          <br></br>
          {`${periFormData.company_name}’s ${currentType} has a PeRi Score of ${periScore} on 100. This means there is a ${periScore}% likelihood that two members of the ${periFormData.company_name} ${currentType} have a difference in perspective.`}
        </Box>
      </Box>
      <Button
        type="submit"
        variant="contained"
        onClick={() => handleNextStep()}
        sx={{
          background: theme.palette.background.paper,
          color: theme.palette.common.white,
          zIndex: 2,
        }}
      >
        RUN SIMULATIONS
      </Button>

      <Box
        sx={{
          fontSize: "16px",
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(3),
        }}
      >
        {`For a full report detailing ${periFormData.company_name} ${currentType}'s strengths, blind spots, opportunities in hiring, and opportunities in. learning and development, please schedule a free consultation..`}
      </Box>
      <Button
        type="submit"
        variant="contained"
        href="https://calendly.com/mathewk"
        target="_blank"
        sx={{
          background: "#43C824",
          color: theme.palette.common.white,
          textTransform: "uppercase",
        }}
      >
        Schedule Free Consultation
      </Button>
    </ContentContainer>
  );
};

export default Evaluate;
