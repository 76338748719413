import { useTheme } from "@mui/material";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

interface RechartRadarChartProps {
  data: {
    subject: string;
    A: number;
    fullMark: number;
  }[];
}

export const RechartRadarChart = ({ data }: RechartRadarChartProps) => {
  const theme = useTheme();
  const isMobile = window.innerWidth < 500;

  function customTick({ payload, x, y, textAnchor, stroke, radius }: any) {
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        {!isMobile ? (
          <text
            radius={radius}
            stroke={stroke}
            x={x}
            y={y}
            className="recharts-text recharts-polar-angle-axis-tick-value"
            textAnchor={textAnchor}
          >
            <tspan x={x} dy="0em">
              {payload.value}
            </tspan>
          </text>
        ) : (
          ""
        )}
      </g>
    );
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="50%" data={data}>
        <PolarGrid style={{ overflow: "visible", zIndex: 4 }} />
        <PolarAngleAxis dataKey="subject" tick={customTick} />
        <PolarRadiusAxis tick={customTick} />
        <Radar
          name="test"
          dataKey="A"
          stroke={theme.palette.background.paper}
          fill={theme.palette.background.paper}
          fillOpacity={0.2}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default RechartRadarChart;
