import { useTheme, Box } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";

export const PeriSignUp = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSignUp = async (event: any) => {
    event?.preventDefault();
    const signUpBody = {
      username: formData.username,
      email: formData.email,
      password: formData.password,
    };

    try {
      await fetch(`${API_URL}/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(signUpBody),
      });
      navigate("/peri/log-in");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "@media (max-width: 768px)": {
          width: "100%",
        },
      }}
    >
      <TextField
        label="Name"
        name="username"
        value={formData.username}
        onChange={handleChange}
        sx={{ marginBottom: "20px" }}
        required
      />
      <TextField
        label="Work Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        sx={{ marginBottom: "20px" }}
        required
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        sx={{ marginBottom: "20px" }}
        required
      />
      <Button
        type="submit"
        variant="contained"
        onClick={handleSignUp}
        sx={{
          width: "100%",
          fontSize: "20px",
          background: theme.palette.background.paper,
          color: theme.palette.common.white,
          marginTop: theme.spacing(2),
        }}
      >
        Continue
      </Button>
    </Box>
  );
};

export default PeriSignUp;
