import { ButtonGroup, Button as MuiButton, useTheme, Box } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "../../components";
import {
  calculateAverage,
  getFromLocalStorage,
  setInLocalStorage,
} from "../../utils";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, MEMBER_TYPE, PERI_STEPS } from "../../constants";
import { PeriSignUp } from "./peri-sign-up";

export const PeriLogin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const [error, setError] = useState(false);
  const periFormData = getFromLocalStorage("periFormData");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchAppData = async (respData: any, username: string) => {
    if (
      respData.user_state === PERI_STEPS.ADD_COMPANY ||
      respData.user_state === PERI_STEPS.CALCULATE
    ) {
      setInLocalStorage("periFormData", {
        company_id: respData.most_recent_company.id,
        company_name: respData.most_recent_company.name,
        username: username,
        step: respData.user_state,
      });
      navigate(`/peri/${respData.user_state}`);
    } else {
      const boardMemeberResp = await fetch(
        `${API_URL}/board-members/${respData.most_recent_company.id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const boardMemberData = await boardMemeberResp.json();
      if (boardMemberData.board_members) {
        const hasBoardData = boardMemberData.board_members.find(
          (data: any) => data.member_type === MEMBER_TYPE.BOARD
        );
        const hasMGMTData = boardMemberData.board_members.find(
          (data: any) => data.member_type === MEMBER_TYPE.MANAGEMENT
        );

        if (hasBoardData && hasMGMTData) {
          const boardResponse = await fetch(
            `${API_URL}/scores/${respData.most_recent_company.id}?includeProvisional=false&member_type=${MEMBER_TYPE.BOARD}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          const boardScoreData = await boardResponse.json();
          const scoreBoardData = Object.entries(boardScoreData)
            .filter(
              (x, i) =>
                x[0] !== "company_id" &&
                x[0] !== "provisional" &&
                x[0] !== "member_type" &&
                x[0] !== "year"
            )
            .map((x) => x[1]) as number[];
          const mgmtResponse = await fetch(
            `${API_URL}/scores/${respData.most_recent_company.id}?includeProvisional=false&member_type=${MEMBER_TYPE.MANAGEMENT}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          const mgmtScoreData = await mgmtResponse.json();
          const scoreMGMTData = Object.entries(mgmtScoreData)
            .filter(
              (x, i) =>
                x[0] !== "company_id" &&
                x[0] !== "provisional" &&
                x[0] !== "member_type" &&
                x[0] !== "year"
            )
            .map((x) => x[1]) as number[];
          setInLocalStorage("periFormData", {
            company_id: respData.most_recent_company.id,
            company_name: respData.most_recent_company.name,
            username: username,
            step: respData.user_state,
            singleMemberType: undefined,
            memberType: MEMBER_TYPE.BOARD,
            [MEMBER_TYPE.BOARD]: {
              periScore: calculateAverage(scoreBoardData),
            },
            [MEMBER_TYPE.MANAGEMENT]: {
              periScore: calculateAverage(scoreMGMTData),
            },
          });
          navigate(`/peri/${respData.user_state}`);
        } else if (hasBoardData && !hasMGMTData) {
          const boardResponse = await fetch(
            `${API_URL}/scores/${respData.most_recent_company.id}?includeProvisional=false&member_type=${MEMBER_TYPE.BOARD}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          const boardScoreData = await boardResponse.json();
          const scoreData = Object.entries(boardScoreData)
            .filter(
              (x, i) =>
                x[0] !== "company_id" &&
                x[0] !== "provisional" &&
                x[0] !== "member_type" &&
                x[0] !== "year"
            )
            .map((x) => x[1]) as number[];
          setInLocalStorage("periFormData", {
            company_id: respData.most_recent_company.id,
            company_name: respData.most_recent_company.name,
            username: username,
            step: respData.user_state,
            singleMemberType: hasBoardData
              ? MEMBER_TYPE.BOARD
              : MEMBER_TYPE.MANAGEMENT,
            memberType: MEMBER_TYPE.BOARD,
            [MEMBER_TYPE.BOARD]: {
              periScore: calculateAverage(scoreData),
            },
          });
          navigate(`/peri/${respData.user_state}`);
        } else if (!hasBoardData && hasMGMTData) {
          const mgmtResponse = await fetch(
            `${API_URL}/scores/${respData.most_recent_company.id}?includeProvisional=false&member_type=${MEMBER_TYPE.MANAGEMENT}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          const mgmtScoreData = await mgmtResponse.json();
          const scoreData = Object.entries(mgmtScoreData)
            .filter(
              (x, i) =>
                x[0] !== "company_id" &&
                x[0] !== "provisional" &&
                x[0] !== "member_type" &&
                x[0] !== "year"
            )
            .map((x) => x[1]) as number[];
          setInLocalStorage("periFormData", {
            company_id: respData.most_recent_company.id,
            company_name: respData.most_recent_company.name,
            username: username,
            step: respData.user_state,
            singleMemberType: hasBoardData
              ? MEMBER_TYPE.BOARD
              : MEMBER_TYPE.MANAGEMENT,
            memberType: MEMBER_TYPE.MANAGEMENT,
            [MEMBER_TYPE.MANAGEMENT]: {
              periScore: calculateAverage(scoreData),
            },
          });
          navigate(`/peri/${respData.user_state}`);
        }
      }
    }
  };

  const handleLogin = async (event: any) => {
    event?.preventDefault();
    const signUpBody = {
      username: formData.username,
      password: formData.password,
    };

    await fetch(`${API_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(signUpBody),
    })
      .then((res) => res.json())
      .then((respData) => {
        fetchAppData(respData, formData.username);
      })
      .catch((error) => {
        setError(true);
      });
  };

  return (
    <ContentContainer
      sx={{
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: theme.spacing(8),
          marginTop: theme.spacing(5),
          "@media (max-width: 768px)": {
            flexDirection: "column-reverse",
            gap: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "65px",
              display: "flex",
              marginBottom: "32px",
            }}
          >
            <Box
              onClick={() => navigate("/peri/log-in")}
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderBottom:
                  params.step === "log-in"
                    ? `3px solid ${theme.palette.background.paper}`
                    : "",
                padding: "30px",
              }}
            >
              Log In
            </Box>
            <Box
              onClick={() => navigate("/peri/sign-up")}
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderBottom:
                  params.step === "sign-up"
                    ? `3px solid ${theme.palette.background.paper}`
                    : "",
                padding: "30px",
              }}
            >
              Sign Up
            </Box>
          </Box>

          {params.step === "sign-up" && <PeriSignUp />}
          {params.step === "log-in" && (
            <>
              <TextField
                label="Name"
                name="username"
                value={formData.username}
                onChange={handleChange}
                sx={{ marginBottom: "20px" }}
                required
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                sx={{ marginBottom: "20px" }}
                required
              />
              <Box
                sx={{
                  color: "red",
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                {error ? "Invalid username or password" : ""}
              </Box>

              <Button
                type="submit"
                variant="contained"
                onClick={handleLogin}
                sx={{
                  width: "100%",
                  fontSize: "20px",
                  background: theme.palette.background.paper,
                  color: theme.palette.common.white,
                  marginTop: theme.spacing(2),
                }}
              >
                Continue
              </Button>
            </>
          )}
        </Box>
      </Box>
    </ContentContainer>
  );
};

export default PeriLogin;
