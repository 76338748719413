import { Container, styled, Box } from "@mui/material";
import { NavElementLink } from "../../components";
import { getFromLocalStorage } from "../../utils";

export const LandingPage = () => {
  const periFormData = getFromLocalStorage("periFormData");
  return (
    <Wrapper>
      <Container
        sx={{
          display: "flex",
          gap: "40px",
          paddingTop: "50px",
          maxWidth: "1000px !important",
        }}
      >
        <NavElement
          href="https://drive.google.com/file/d/1j7wzhmviTaLTydFfDJ93D3s5QJAEw4vP/view?usp=sharing"
          target="_blank"
        >
          Read the White Paper
        </NavElement>
        <NavElementLink to={"/peri/log-in"}>
          Calculate my PeRi Score
        </NavElementLink>
      </Container>
      <LandingPageContainer>
        <h1>Beyond Diversity</h1>
        <h2>
          Beyond demographics, towards <span>diverse perspectives.</span>
        </h2>
      </LandingPageContainer>
    </Wrapper>
  );
};

export default LandingPage;

const LandingPageContainer = styled(Container)(
  ({ theme }) => `
    margin-top: 30vh;
    max-width: 1000px !important;
    h1 {
        color: ${theme.palette.primary.contrastText};
        font-size: 72px;
        @media (max-width: 768px) {
          font-size: 50px;
        }
    }
    h2 {
        font-size: 32px;
        color: ${theme.palette.primary.dark};
    }
    span {
        color: ${theme.palette.common.black};
    }
    @media (max-width: 768px) {
      margin-top: 50px;
    }
        `
);

const Wrapper = styled(Box)(
  ({ theme }) => `
    height: 90vh;
    /*
    * Created with https://www.css-gradient.com
    * Gradient link: https://www.css-gradient.com/?c1=f8cfb1&c2=eeeae2&gt=l&gd=dtt
    */
    background: #F8CFB1;
    background: linear-gradient(180deg, #F8CFB1, #EEEAE2);
    @media (max-width: 768px) {
      height: 700px;
    }
}
        `
);

const NavElement = styled("a")(
  ({ theme }) => `
  cursor: pointer;
  color: ${theme.palette.common.black};
  text-decoration: none;
  transition: 0.3s ease;
    &:hover {
    text-decoration: underline;
    }
        `
);
