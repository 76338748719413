import { Box, Button, Checkbox, useTheme } from "@mui/material";
import {
  ContentContainer,
  RadarChart,
  SectionTitle,
  Title,
  DataTable,
  Toggle,
} from "../../components";
import {
  calculateAverage,
  getFromLocalStorage,
  setInLocalStorage,
} from "../../utils";
import { useEffect, useState } from "react";
import { API_URL, MEMBER_TYPE } from "../../constants";
import { BoardMemberData } from "../../types";

export const Simulate = () => {
  const theme = useTheme();
  const periFormData = getFromLocalStorage("periFormData");
  const [boardMemberData, setBoardMemberData] = useState<
    BoardMemberData[] | []
  >([]);
  const [allTableData, setAllTableData] = useState<any[]>([]);
  const [currentIds, setCurrentIds] = useState<any[]>([]);
  const [provisionalIds, setProvisionalIds] = useState<any[]>([]);
  const [provisionalAllIds, setProvisionalAllIds] = useState<any[]>([]);
  const [periScore, setPeriScore] = useState<number>(0);
  const [radarChartData, setRadarChartData] = useState<any[]>([]);
  const [option, setOption] = useState<number>(0);
  const memberType = periFormData.singleMemberType
    ? periFormData.singleMemberType
    : option === 0
    ? MEMBER_TYPE.BOARD
    : MEMBER_TYPE.MANAGEMENT;

  const isMobile = window.innerWidth < 500;

  useEffect(() => {}, []);

  const handleTogge = (event: React.SyntheticEvent, newValue: number) => {
    setOption(newValue);
    fetchBoardData();
    fetchPeRiScore(newValue === 1 ? MEMBER_TYPE.MANAGEMENT : MEMBER_TYPE.BOARD);
    setCurrentIds([]);
    setProvisionalAllIds([]);
    setProvisionalIds([]);
  };

  const fetchPeRiScore = async (memberType: string) => {
    try {
      const response = await fetch(
        `${API_URL}/scores/${periFormData.company_id}?includeProvisional=false&member_type=${memberType}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const periScoreData = await response.json();
      const chartData = Object.entries(periScoreData).filter(
        (x, i) =>
          x[0] !== "company_id" &&
          x[0] !== "provisional" &&
          x[0] !== "member_type" &&
          x[0] !== "year"
      );

      const periNumberData = chartData.map((x) => x[1]) as number[];

      setRadarChartData(chartData);
      setPeriScore(calculateAverage(periNumberData));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBoardData();
    fetchPeRiScore(memberType);
    setCurrentIds([]);
    setProvisionalAllIds([]);
    setProvisionalIds([]);
  }, []);

  const fetchBoardData = async () => {
    try {
      const boardMemeberResp = await fetch(
        `${API_URL}/board-members/${periFormData.company_id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const resp = await boardMemeberResp.json();
      setAllTableData(resp.board_members as BoardMemberData[]);
      const result = resp.board_members.filter(
        (data: any) => data.member_type === memberType
      );
      setBoardMemberData(result);
      setProvisionalAllIds(
        result
          .filter((x: BoardMemberData) => x.provisional)
          .map((x: BoardMemberData) => x.id)
      );
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCheckBox = (id: number, isProvisional: boolean) => {
    if (currentIds.includes(id) && !isProvisional) {
      setCurrentIds(currentIds.filter((x) => x !== id));
    } else if (!isProvisional) {
      setCurrentIds([...currentIds, id]);
    }

    if (provisionalIds.includes(id) && isProvisional) {
      setProvisionalIds(provisionalIds.filter((x) => x !== id));
    } else if (isProvisional) {
      setProvisionalIds([...provisionalIds, id]);
    }
  };

  const handleRecalculate = async (
    event?: React.MouseEvent<HTMLButtonElement>,
    toggleOption?: number,
    startingQuery?: string
  ) => {
    event?.preventDefault();
    const idsForQuery = provisionalAllIds.filter(
      (x) => !provisionalIds.includes(x)
    );
    const queryString = [...currentIds, ...idsForQuery].join(",");
    const showProvisional = provisionalIds.length > 0 ? true : false;
    const URL =
      queryString.length > 0
        ? `${API_URL}/scores/${periFormData.company_id}?includeProvisional=${showProvisional}&member_type=${memberType}&excludedIds=${queryString}`
        : `${API_URL}/scores/${periFormData.company_id}?includeProvisional=${showProvisional}&member_type=${memberType}`;

    try {
      const response = await fetch(URL, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      const periScoreData = await response.json();
      const chartData = Object.entries(periScoreData).filter(
        (x, i) =>
          x[0] !== "company_id" &&
          x[0] !== "provisional" &&
          x[0] !== "member_type" &&
          x[0] !== "year"
      );

      const periNumberData = chartData.map((x) => x[1]) as number[];

      setRadarChartData(chartData);
      setPeriScore(calculateAverage(periNumberData));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (memberType && allTableData.length > 0) {
      const result = allTableData.filter(
        (data: any) => data.member_type === memberType
      );
      setBoardMemberData(result);
    }
  }, [memberType, allTableData]);

  return (
    <ContentContainer sx={{ paddingTop: "0px" }}>
      <Box sx={{ marginBottom: theme.spacing(6) }}>
        <Title
          sx={{
            textAlign: "left",
            color: theme.palette.primary.contrastText,
            marginBottom: "0px",
          }}
        >
          Run Simulations of your PeRi Score
        </Title>
        <SectionTitle
          sx={{
            textAlign: "left",
            marginBottom: theme.spacing(0.5),
            color: theme.palette.primary.contrastText,
            textTransform: "capitalize",
            "@media (max-width: 500px)": {
              marginBottom: "110px",
            },
          }}
        >
          {`${periFormData.company_name} ${memberType}`}
        </SectionTitle>
        <Box sx={{ fontSize: "16px", marginBottom: theme.spacing(2) }}>
          In this module, test how your team’s PeRi Score could change in the
          future.
        </Box>
      </Box>
      <Box sx={{ marginBottom: theme.spacing(6) }}>
        <SectionTitle
          sx={{
            textAlign: "left",
            marginBottom: theme.spacing(0.5),
            color: theme.palette.primary.contrastText,
            "@media (max-width: 500px)": {
              marginBottom: "110px",
            },
          }}
        >
          1. Add potential new hires to test their impact your PeRi Score
        </SectionTitle>
        <Box sx={{ fontSize: "16px", marginBottom: theme.spacing(2) }}>
          Add new candidates you are considering for your board or management.
          See which candidates have the biggest impact on your PeRi Score below.
        </Box>
      </Box>
      {!periFormData.singleMemberType && (
        <Toggle selectedOption={option} onChange={handleTogge} />
      )}
      <Box sx={{ marginBottom: theme.spacing(6), zIndex: 5 }}>
        <DataTable
          provisionalTable={true}
          onSubmitCallback={fetchBoardData}
          memberType={memberType}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box sx={{ marginBottom: theme.spacing(6) }}>
          <SectionTitle
            sx={{
              textAlign: "left",
              marginBottom: theme.spacing(0.5),
              color: theme.palette.primary.contrastText,
              "@media (max-width: 500px)": {
                marginBottom: "110px",
              },
            }}
          >
            2. Simulate your PeRi Score as your team evolves
          </SectionTitle>
          <Box sx={{ fontSize: "16px", marginBottom: theme.spacing(2) }}>
            {`Uncheck 
            ${memberType} members
            and recalculate your PeRi Score`}
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              minHeight: "750px",
              justifyContent: "space-between",
              zIndex: 5,
            }}
          >
            <Box>
              <Box sx={{ marginBottom: theme.spacing(3) }}>
                <Box
                  sx={{ fontSize: "16px", textTransform: "capitalize" }}
                >{`Current ${memberType}`}</Box>
                {boardMemberData.length > 0 &&
                  boardMemberData
                    ?.filter((x: BoardMemberData) => x?.provisional === false)
                    .map(({ name, id }: BoardMemberData) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        key={id}
                      >
                        <Checkbox
                          onChange={() => handleCheckBox(id, false)}
                          sx={{
                            color: theme.palette.background.paper,
                            padding: "4px",
                            "&.Mui-checked": {
                              color: theme.palette.background.paper,
                            },
                          }}
                          checked={!currentIds?.includes(id) ? true : false}
                        />
                        <Box sx={{ fontSize: "16px" }}>{name}</Box>
                      </Box>
                    ))}
              </Box>
              <Box>
                <Box
                  sx={{ fontSize: "16px", textTransform: "capitalize" }}
                >{`New ${memberType} Hires`}</Box>
                {boardMemberData.length > 0 &&
                  boardMemberData
                    ?.filter((x: BoardMemberData) => x?.provisional === true)
                    .map(({ name, id }: BoardMemberData) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        key={id}
                      >
                        <Checkbox
                          onChange={() => handleCheckBox(id, true)}
                          sx={{
                            color: theme.palette.background.paper,
                            padding: "4px",
                            "&.Mui-checked": {
                              color: theme.palette.background.paper,
                            },
                          }}
                          checked={provisionalIds?.includes(id) ? true : false}
                        />
                        <Box sx={{ fontSize: "16px" }}>{name}</Box>
                      </Box>
                    ))}
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                size="large"
                onClick={(event) => handleRecalculate(event, option)}
                sx={{
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(5),
                  width: "364px",
                  background: theme.palette.background.paper,
                  color: theme.palette.common.white,
                  zIndex: 2,
                }}
              >
                Recalculate
              </Button>
              <Box
                sx={{
                  fontSize: "32px",
                  marginBottom: theme.spacing(2),
                  color: theme.palette.primary.dark,
                }}
              >
                Current PeRi Score{" "}
                {periFormData[memberType] && !periFormData.hideToggle
                  ? periFormData[memberType].periScore
                  : periFormData[MEMBER_TYPE.BOARD]
                  ? periFormData[MEMBER_TYPE.BOARD].periScore
                  : ""}
              </Box>
              <Box sx={{ fontSize: "32px", marginBottom: theme.spacing(2) }}>
                Simulated PeRi Score {periScore}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ width: "100%", marginLeft: isMobile ? "-135px" : "0px" }}
            >
              <RadarChart
                memberType={memberType}
                diversityData={
                  memberType === MEMBER_TYPE.BOARD
                    ? radarChartData
                    : radarChartData.filter(
                        (x, i) =>
                          x[0] !== "num_other_boards" &&
                          x[0] !== "primary_industry_focus"
                      )
                }
                marginTopValue="-200px"
                marginLeftValue="250px"
                topValue={isMobile ? "21.5%" : "29%"}
                centerLeftValue="75.5%"
                heightValue={isMobile ? "600px" : "980px"}
                widthValue={isMobile ? "600px" : "980px"}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ContentContainer>
  );
};

export default Simulate;
